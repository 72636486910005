.bytt-jobs-display-jd * {
  margin: 0;
  padding: 0; }

.bytt-jobs-display-jd .bytt-jd-template {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px; }

.bytt-jobs-display-jd .bytt-jd-card {
  border: 1px solid #ccc;
  position: relative;
  height: 100%; }

.bytt-jobs-display-jd .bytt-jd-card-body {
  padding: 11px; }

.bytt-jobs-display-jd .bytt-jd-logo {
  text-align: center; }
  .bytt-jobs-display-jd .bytt-jd-logo img {
    height: 64px; }

.bytt-jobs-display-jd .bytt-jd-badge {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
  padding: 2px;
  background-color: #175aa6;
  color: #fff;
  font-size: 11px; }
